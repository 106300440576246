
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";

export default defineComponent({
  name: "border-width",
  components: {
    CodeHighlighter
  },
  setup() {
    const widths = [0, 1, 2, 3, 4, 5];

    return {
      widths
    };
  }
});
